body,
html {
  font-size: 10px;
}
* {
  box-sizing: border-box;
}

#main-content {
  margin-top: 56px;
}

#footer-content {
  position: relative;
  z-index: 2;
}

a {
  all: unset;
  cursor: pointer;
}

@media (min-width: 600px) {
  #main-content {
    margin-top: 64px;
  }
}

@media (max-width: 600px) and (orientation: landscape) {
  #main-content {
    margin-top: 48px;
  }
}

/* Brute force the picker day font */
.MuiPickersDay-day p {
  font-family: "Rubik" !important;
}
